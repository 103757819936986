export const MARKETING_WEBSITE_URL = "https://conveo.ai";
export const HOST =
  process.env.NODE_ENV === "production"
    ? "https://conveo.ai"
    : "http://localhost:3000";

export function urlFor(path: string) {
  return `${HOST}${path}`;
}

type InterviewSlugPathParams = {
  interviewSlug: string;
  studySlug: string;
};

export const PRIVACY_POLICY_PATH = "/privacy-policy";
export const TERMS_OF_SERVICE_PATH = "/terms-of-service";
export const ADMIN_PATH = "/admin";
export const ADMIN_EMAILS_PATH = "/admin/emails";
export const API_STUDY_DELETE = (studyId: string) =>
  `/api/studies/${studyId}/delete`;
export const API_STUDY_COMPLETE = (studyId: string) =>
  `/api/studies/${studyId}/complete`;
export const API_STUDY_UPDATE_SLUG = (studyId: string) =>
  `/api/studies/${studyId}/update-slug`;
export const API_STUDY_DUPLICATE = (studyId: string) =>
  `/api/studies/${studyId}/duplicate`;
export const API_STUDY_UPDATE_EMAIL_REQ = (studyId: string) =>
  `/api/studies/${studyId}/update-email-req`;
export const API_ROLE_DELETE = (userId: string) =>
  `/api/roles/${userId}/delete`;
export const API_ROLE_UPDATE = (userId: string) =>
  `/api/roles/${userId}/update`;
export const API_TEAM_INVITE_DELETE = (teamInviteId: string) =>
  `/api/team-invites/${teamInviteId}/delete`;
export const API_PROJECT_DELETE = (projectId: string) =>
  `/api/projects/${projectId}/delete`;
export const API_QUESTION_DELETE = (questionId: string) =>
  `/api/questions/${questionId}/delete`;
export const API_SAVE_GRID_STYLE = "/api/save-grid-style";
export const API_DOWNLOAD_LINKS = (respondentUploadId: string) =>
  `/api/uploads/${respondentUploadId}/download`;
export const API_GENERATE_QUESTION_SUMMARY = (questionId: string) =>
  `/api/questions/${questionId}/generate-summary`;
export const API_DATA_EXPORT_DOWNLOAD = (dataExportId: string) =>
  `/api/data-exports/${dataExportId}/download`;
export const API_CONNECT_VIEW = (questionId: string) =>
  `/api/questions/${questionId}/connect-view`;
export const API_DISCONNECT_VIEW = (questionId: string) =>
  `/api/questions/${questionId}/disconnect-view`;
export const API_AI_TTS = "/api/ai/tts";
export const API_AI_TTS_AZURE = "/api/ai/tts/azure";
export const API_INTERVIEW_AI_CHAT = (interviewId: string) =>
  `/api/interviews/${interviewId}/ai/chat`;
export const API_INTERVIEW_AI_STT_CHAT = (interviewId: string) =>
  `/api/interviews/${interviewId}/ai/stt-chat`;
export const AUTH_LOGIN = (props?: {
  email?: null | string;
  rdr?: null | string;
}) => {
  const queryParams = Object.entries(props ?? {})
    .filter((entry): entry is [string, string] => entry[1] != null)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  return `/auth/login${queryParams === "" ? "" : `?${queryParams}`}` as const;
};
export const AUTH_REGISTER = (props?: {
  email?: null | string;
  rdr?: null | string;
}) => {
  const queryParams = Object.entries(props ?? {})
    .filter((entry): entry is [string, string] => entry[1] != null)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  return `/auth/register${queryParams === "" ? "" : `?${queryParams}`}`;
};
export const AUTH_LOGOUT = "/auth/logout";
export const BASE_PATH = "/";
export const DASHBOARD_PATH = "/dashboard";
export const FEEDBACK_FORM_PATH = "/interviews/Conveo-feedback";
export const INTERVIEW_SLUG_PATH = (
  studySlug: string,
  interviewSlug: string,
  assistantId?: string,
) =>
  `/interviews/${studySlug}/${interviewSlug}${assistantId == null ? "" : `?aid=${assistantId}`}`;
export const INTERVIEW_WELCOME_PATH = (
  studySlug: string,
  interviewSlug: string,
) => `/interviews/${studySlug}/welcome/${interviewSlug}`;
export const INTERVIEW_PAUSED_PATH = (
  studySlug: string,
  interviewSlug: string,
) => `/interviews/${studySlug}/${interviewSlug}/paused`;
export const INTERVIEW_WELCOME_BACK_PATH = ({
  interviewSlug,
  studySlug,
}: InterviewSlugPathParams) =>
  `/interviews/${studySlug}/welcome-back/${interviewSlug}`;
export const INTERVIEW_COMPLETED_PATH = (studySlug: string) =>
  `/interviews/${studySlug}/completed`;
export const INTERVIEW_END_PATH = ({
  interviewSlug,
  studySlug,
}: InterviewSlugPathParams) => `/interviews/${studySlug}/${interviewSlug}/end`;
export const INTERVIEW_PATH = (studySlug: string, params?: "preview"[]) =>
  `/interviews/${studySlug}${params != null && params.length > 0 ? `?${params.map((param) => `${param}=true`).join("&")}` : ""}`;
export const PROFILE_PATH = "/profile";
export const PROJECT_CREATE_PATH = "/projects/create";
export const PROJECT_PATH = (projectId: string) => `/projects/${projectId}`;
export const SETTINGS_PATH = "/settings";
export const SETTINGS_DEVELOPERS_PATH = "/settings/developers";
export const SETTINGS_INTEGRATIONS_PATH = "/settings/integrations";
export const SETTINGS_DEVELOPERS_CREATE_PATH = "/settings/developers/create";
export const SETTINGS_MEMBER_MANAGEMENT_PATH = "/settings/members";
export const SETTINGS_GENERAL_PATH = "/settings/general";
export const SETTINGS_INVOICE_SETTINGS_PATH = "/settings/invoice-settings";
export const SETTINGS_AUTHENTICATION_PATH = "/settings/authentication";
export const SETTINGS_LEGAL_PATH = "/settings/legal";
export const STUDY_PATH = (props: { studyId: string }) =>
  `/studies/${props.studyId}`;
export const STUDY_SUMMARY_PATH = (props: { studyId: string }) =>
  `/studies/${props.studyId}/summary`;
export const STUDY_INTERVIEW_PATH = (props: {
  interviewSlug: string;
  studyId: string;
}) => `/studies/${props.studyId}/interviews/${props.interviewSlug}`;
export const STUDY_INTERVIEWS_PATH = (props: { studyId: string }) =>
  `/studies/${props.studyId}/interviews`;
export const STUDY_INTERVIEWS_EXPORT_PATH = (props: { studyId: string }) =>
  `/studies/${props.studyId}/interviews/export`;
export const STUDY_GUIDING_QUESTIONS_PATH = (props: { studyId: string }) =>
  `/studies/${props.studyId}/guiding-questions`;
export const STUDY_PANEL_INTEGRATION_PATH = (props: { studyId: string }) =>
  `/studies/${props.studyId}/panel-integration`;
export const STUDY_SHARING_OPTIONS_PATH = (props: { studyId: string }) =>
  `/studies/${props.studyId}/sharing-options`;
export const STUDY_QUESTIONS_PATH = (props: { studyId: string }) =>
  `/studies/${props.studyId}/questions`;
export const STUDY_INSIGHTS_PATH = (props: { studyId: string }) =>
  `/studies/${props.studyId}/insights`;
export const STUDY_INSIGHT_PATH = (props: {
  studyId: string;
  themeId: string;
}) => `/studies/${props.studyId}/insights/${props.themeId}`;
export const STUDY_QUESTIONS_QUESTION_PATH = (props: {
  questionId: string;
  studyId: string;
}) => `/studies/${props.studyId}/questions/${props.questionId}`;
export const STUDY_QUESTIONS_FACET_VALUES_PATH = (props: {
  facetId: string;
  studyId: string;
}) => `/studies/${props.studyId}/questions/facet-values/${props.facetId}`;
export const STUDY_CONFIGURE_REPORTING_PATH = (props: { studyId: string }) =>
  `/studies/${props.studyId}/configure-reporting`;
export const STUDY_SETUP_PATH = (props: { studyId: string }) =>
  `/studies/${props.studyId}/setup`;
export const STUDY_MANUAL_PANEL_INTEGRATION_PATH = (props: {
  studyId: string;
}) => `/studies/${props.studyId}/manual-panel-integration`;
export const STUDY_RECALCULATE_REPORTING = (props: { studyId: string }) =>
  `/studies/${props.studyId}/recalculate-reporting`;
export const STUDY_SYNTHETIC_DATA_PATH = (props: { studyId: string }) =>
  `/studies/${props.studyId}/synthetic-data`;
export const API_SET_CURRENT_TEAM_PATH = (teamId: string) =>
  `/api/teams/${teamId}/set-current`;
export const TEAMS_CREATE_PATH = "/teams/create";
export const TEAMS_ACCEPT_INVITE_PATH = (token: string) =>
  `/teams/accept-invite?token=${token}`;
export const TALK_TO_US_URL = "https://calendly.com/hendrik-conveo/30min";
export const ABOUT_PATH = "/about";
export const BLOG_PATH = "/blog";
export const EMAIL_CAPTURE_PATH = "/api/email-capture";
export const STUDY_CREATE_SETUP = (studyId: string) =>
  `/studies/create/${studyId}/setup`;
export const STUDY_CREATE_GUIDING_QUESTIONS = (studyId: string) =>
  `/studies/create/${studyId}/guiding-questions`;
export const STUDY_CREATE_RESEARCH_OBJECTIVES = (studyId: string) =>
  `/studies/create/${studyId}/research-objectives`;
export const STUDY_CREATE_GUIDING_QUESTION = (props: {
  questionId: string;
  studyId: string;
}) => `/studies/create/${props.studyId}/guiding-questions/${props.questionId}`;

export const STUDY_CREATE_CONFIGURE_REPORTING = (studyId: string) =>
  `/studies/create/${studyId}/configure-reporting`;
export const STUDY_CREATE_CONFIGURE_REPORTING_EDIT = (
  studyId: string,
  facetId: string,
) => `/studies/create/${studyId}/configure-reporting/edit/${facetId}`;
export const STUDY_CREATE_CONFIGURE_REPORTING_DELETE = (
  studyId: string,
  facetId: string,
) => `/studies/create/${studyId}/configure-reporting/delete/${facetId}`;
export const STUDY_CREATE_CONFIGURE_REPORTING_ADD_FACET = (
  studyId: string,
  questionId: string,
) => `/studies/create/${studyId}/configure-reporting/${questionId}/add-facet`;
export const STUDY_CREATE_RECRUITMENT = (studyId: string) =>
  `/studies/create/${studyId}/recruitment`;
export const STUDY_CREATE_REVIEW = (studyId: string) =>
  `/studies/create/${studyId}/review`;
